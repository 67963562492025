export * from './userSegmentation';
export * from './errorMessages';
export const PRODUCTS = 'products';
export const EMOTION_CACHE_KEY = "products-app-emotion";
export const REVALIDATE_INTERVAL_IN_SECONDS = 10 * 60;
export const DIGITAL_CATALOGUE_CURRENT = 'digital-catalogue-current';
export const DIGITAL_CATALOGUE_NEXT = 'digital-catalogue-next';
export const CATALOGUES_LANDING_PATH = '/catalogues';
export const ALLOWED_PRODUCT_LISTING_PAGE_TYPES = [
    'plp',
    'category',
    'brands',
    'outlet',
    'sample-shop',
    'new'
];
export const SERVER_PAGINATION_LIMIT_ROWS = 2;
export const GALLERY_PATH = '/gallery';
export const SEARCH_PATH = '/search';
export const LIBRARY_PATHNAME = '/product-philosophy/ingredient-library';
export const FAVOURITE_PRODUCTS_PATH = '/favourite-products';
export const PERSONAL_PRODUCTS_LISTS_PATH = '/mypages/business-tools/share-and-earn/personal-products-lists';
export const PERSONAL_PRODUCTS_LISTS_DETAIL_PATH = '/personal-product-list';
export const MYPAGES_REWARDS_PATH = '/mypages/order/oriflame-rewards';
